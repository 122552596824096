body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  color: rgba(255, 255, 255, 0.87);
}

html {
  background-color: #121212;
  color: rgba(255, 255, 255, 0.87);
  --scrollbarBG: #202022;
  --thumbBG: #585859;
  overflow: hidden;
}

:root {
  color-scheme: dark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

iframe {
  display: none !important;
}